



html, body, p, span, label, strong {
  font-family: 'FS Industrie Ex';
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: 'FS Industrie Ex Book';
}

p {
  line-height: 1.5rem;
}


.hover-zoom {
  overflow: hidden;
}


.hover-zoom:hover img {
  transition: all 1000ms linear;
  transform: scale(1.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: none;
}

.carousel-caption {
  color: #fff;
  bottom: 4rem !important;
  padding-bottom: 0 !important;
}


.carousel-caption h3 {
  font-size: 3rem;
}

.red {
  color: #EB212D
}

.horizontal-icons a:hover p{
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
}

.bg-dark {
 background: #080605 !important;
}

@media (max-width: 1200px){
  #carousel {
    margin-top: 88px;
  }
}

@media (min-width: 1200px){
  .navbar-expand-xl .navbar-toggler {
    display: block !important;
  }
}

.react-parallax-bgimage,
.carousel-item{
  width: 100% !important;
  height: 100% !important;
}


#logo {
  opacity: 0;
  transition: all 200ms linear;
}

#logo.show {
  opacity: 1;
}

@media (max-width: 1200px){
  #logo {
    width: 80px;
    margin-right: 80px;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  margin-top: -200px;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #EB212D;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #EB212D;
}

.text-carousel .carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #000;
}

.text-carousel  .carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #000;
}


.MuiDrawer-root .MuiDrawer-paperAnchorLeft {
  background-color: #080605;
}

.MuiDrawer-root .MuiDrawer-paperAnchorLeft .MuiListItemText-primary {
  color: #6c757d !important;
  font-size: 2.5rem
}

.carousel-indicators li {
  background-color: #000;
}


a:hover {
  text-decoration: underline;
  text-decoration-color: #EB212D;
  -webkit-text-decoration-color: #EB212D;
}

textarea {
  resize: none;
}

#nav-draw  a:hover {
    text-decoration: none;
    color: #fff  !important;
  }


#nav-draw  a:hover span.MuiListItemText-primary {
  text-decoration: none;
  color: #fff !important;
  font-family: 'Montserrat', sans-serif;
}

.mont {
  font-family: 'Montserrat', sans-serif;
}


.mont-bold{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.hover-border {
  border: 1px solid rgba(0, 0, 0, 0);
}

.hover-border:hover{
  border: 1px solid white;
}

#nav-draw .MuiListItemText-primary {
  font-family: 'Montserrat', sans-serif !important;
}

#nav-draw .MuiListItem-gutters {
  padding: 0;
}

.grey {
  color: #706f6f;
    }

.youtube {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#nav-draw .MuiCollapse-container {
  margin-left: 15rem;
}

#nav-draw .MuiListItem-root {
  align-items: baseline;
}


#nav-draw .text-white span {
  color: white !important;
}

.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}